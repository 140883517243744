import axios from 'axios';
import { socket } from '../components/SocketContext/SocketContext';
import Cookies from 'js-cookie';
import { decryptData, encryptLocalData, key } from '../components/GlobalFunction/GlobalFunction';

const apiUrl = process.env.REACT_APP_API_BASE_URL;  

// const API_BASE_URL = "";
const API_BASE_URL = apiUrl;
// const API_BASE_URL = "http://49.43.34.101:1445";

const authCookie = Cookies?.get('auth');

const CancelToken = axios.CancelToken;  
let cancel;

const cancelRequest = () => {
    if (cancel) {
        cancel('Request canceled by user');
    }
};

let pathname = ''

const checkFor404Error = () => {
    const errorValue = localStorage.getItem("Error");
    if (errorValue === "404") {
        window.location.assign('/404');
    }
};

const checkForUserData = () => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
        window.location.assign('/404');
    }
};

export async function Post(path, params) {
    // checkFor404Error();
    cancelRequest();
    // checkForUserData();

    const headerValue = sessionStorage.getItem('header');
    // const sad = JSON.parse(headerValue); 
    // const decryptedRes = decryptData(sad.encryptedData, sad.iv, key);

    // const parsedRes = JSON.parse(decryptedRes); 

    const config = {
        headers: {
            'x-encryption-key': headerValue,
            'Authorization': authCookie,
        },
        cancelToken: new CancelToken(function executor(c) {
            if (path !== "CmbFill") {
                if (pathname === path) {
                    cancel = c;
                };
                pathname = path;
            };
        })
    };

    try {
        const response = await axios.post(`${API_BASE_URL}/${path}`, params, config);
        if (response.status === 200) {

            pathname = "";
        }
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            // console.log('Request canceled', error.message);
        } else {
            if (error?.response?.status === 404) {
                encryptLocalData("ID", false);
                sessionStorage.removeItem("header");
                localStorage.removeItem("userData");
                localStorage.setItem("Error", error.response.status);
                return window.location.assign('/404');
            };
        };
    };
};


export async function Soket(params) {
    return new Promise((resolve, reject) => {
        let data;

        if (params.token) {
            data = {
                CompanyCode: params.UserData.CompanyCode,
                UserId: params.UserData.UserId,
                Pass: params.UserData.UserId,
                token: params.token
            };
        } else {
            data = {
                CompanyCode: params.UserData.CompanyCode,
                UserId: params.UserData.UserId,
                Pass: params.UserData.UserId
            };
        }

        if (!socket.connected) {
            console.error('Socket is not connected.');
            reject(new Error('Socket is not connected.'));
            return;
        }

        socket.emit('authenticate', data, (response) => {
            // console.log('Received authenticate response:', response);

            if (response.error) {
                reject(response.error);
            } else {
                resolve(response);

            }
        });
    });
}

export async function Get(path, params) {
    cancelRequest();

    const headerValue = sessionStorage.getItem('header'); 
    
    const config = {
        headers: {
            'x-encryption-key': headerValue,
            'Authorization': authCookie,
        },
        params: params,
        cancelToken: new CancelToken(function executor(c) {
            if (pathname === path) {
                cancel = c;
            };
        })
    };

    try {
        const response = await axios.get(`${API_BASE_URL}/${path}`, config);
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            // console.log('Request canceled', error.message);
        } else {
            throw error;
        };
    };
};


export async function GetWithHeader(path, params, outh) {
    cancelRequest();

    const headerValue = sessionStorage.getItem('header'); 
    
    const config = {
        headers: {
            'x-encryption-key': headerValue,
            'Authorization': outh,
        },
        params: params,
        cancelToken: new CancelToken(function executor(c) {
            if (pathname === path) {
                cancel = c;
            };
        })
    };

    try {
        const response = await axios.get(`${API_BASE_URL}/${path}`, config);
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            // console.log('Request canceled', error.message);
        } else {
            throw error;
        };
    };
};
