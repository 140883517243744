import React, { useState } from 'react';
import './Sidebar.scss';
import { PanelMenu } from 'primereact/panelmenu';
import AddParties from '../Pages/Parties/AddParties/AddParties';
import { useNavigate } from 'react-router-dom';
import img from '../../assets/img/avatar.webp';
import { getFcodeByPath } from '../GlobalFunction/GlobalFunction';

function Sidebar(props) {
    const [visible, setVisible] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const navigator = useNavigate();
    const permissionCheck = JSON.parse(localStorage.getItem('userData'));

    const onClickRouter = (path, item) => {

        const fcode = getFcodeByPath(path.name.toLowerCase());
        const pathName = path.name.toLowerCase();
        props.setSidebarOpen(true);

        navigator(`/${pathName}/${fcode}`);
        setActiveItem(path.name);

        sessionStorage.removeItem('item');
        sessionStorage.removeItem('saleInvoice');
        sessionStorage.removeItem('saleOrder');
        sessionStorage.removeItem('saleReturn');
        sessionStorage.removeItem('purchanseBill');
        sessionStorage.removeItem('purchaseReturn');
        sessionStorage.removeItem('expenseId');
    }

    const itemRenderer = (item, index) => {
        return (
            <a key={index} className={`flex align-items-center px-3 py-2 cursor-pointer ${activeItem === item.name ? 'active' : ''}`} onClick={() => onClickRouter(item)}>
                <div className='text'>
                    <span className={`${item.icon} text-primary`} />
                    <h6 className={`mx-2`}>{item.label}</h6>
                </div>
                {item.arrow && <i className={`pi ${item.arrow}`} onClick={(e) => handleArrowClick(e, item)}></i>}
            </a>
        )
    };

    const onClickRouter2 = (path, item) => {
        props.setSidebarOpen(true);
    }

    const itemRenderer2 = (item, index) => (
        <a key={index} className={`flex align-items-center px-3 py-2 cursor-pointer ${activeItem === item.name ? 'active' : ''}`} onClick={() => onClickRouter2(item)}>
            <div className='text'>
                <span className={`${item.icon} text-primary`} />
                <h6 className={`mx-2`}>{item.label}</h6>
            </div>
            {item.arrow && <i className={`pi ${item.arrow}`}></i>}
        </a>
    );

    const handleArrowClick = (e, item) => {
        e.stopPropagation();
        // if (item.name === 'Parties' && e.target.classList.contains('pi-plus')) {
        //     setVisible(true);
        // } else if (item.name === 'Items' && e.target.classList.contains('pi-plus')) {
        //     navigator('./additems');
        // } else if (item.name === 'SaleInvoices' && e.target.classList.contains('pi-plus')) {
        //     navigator('./addSaleInvoices');
        // } else if (item.name === 'SaleReturn' && e.target.classList.contains('pi-plus')) {
        //     navigator('./addCreditNote');
        // } else if (item.name === 'SaleOrder' && e.target.classList.contains('pi-plus')) {
        //     navigator('./saleaddorder');
        // } else if (item.name === 'PurchaseBills' && e.target.classList.contains('pi-plus')) {
        //     navigator('./addPurchaseBills');
        // } else if (item.name === 'PurchaseReturn' && e.target.classList.contains('pi-plus')) {
        //     navigator('./addDebitNote');
        // } else if (item.name === 'Expensive' && e.target.classList.contains('pi-plus')) {
        //     navigator('./expensive/addExpensive');
        // }
        // else {
        //     if (e.target.classList.contains('pi-plus')) {
        //         alert(`Arrow clicked for ${item.label}`);
        //     }
        // }
    };

    const hasPermission = (Fcode, permissionType) => {
        const Permissions = permissionCheck?.Permissions || [];
        const permission = Permissions.find((p) => p.F_Code === Fcode);
        const hasPermission = permission ? permission[permissionType] : false;
        return hasPermission;
    };

    const filterItems = (items) => {
        return items.reduce((filtered, item) => {
            if (item.template && hasPermission(item.Fcode, 'is_View')) {
                let filteredItem = { ...item };
                if (item.items) {
                    const filteredItems = filterItems(item.items);
                    if (filteredItems.length > 0) {
                        filteredItem.items = filteredItems;
                        filtered.push(filteredItem);
                    }
                } else {
                    filtered.push(filteredItem);
                }
            }
            return filtered;
        }, []);
    };

    const items = filterItems([
        {
            label: 'Home',
            name: 'Home',
            icon: 'pi pi-home',
            Fcode: "HOM",
            template: itemRenderer,
        },
        {
            label: 'Parties',
            name: 'Parties',
            icon: 'pi pi-users',
            Fcode: "PRT",
            arrow: "pi-plus",
            template: itemRenderer,
        },
        {
            label: 'Items',
            name: 'Items',
            icon: 'pi pi-objects-column',
            Fcode: "ITM",
            arrow: "pi-plus",
            template: itemRenderer,
        },
        {
            label: 'Sale',
            name: 'Sale',
            icon: 'pi pi-book',
            Fcode: "SLE",
            arrow: "pi-angle-down",
            template: itemRenderer2,
            items: [
                {
                    name: 'SaleInvoices',
                    label: 'Sale Invoices',
                    arrow: "pi-plus",
                    Fcode: "SLN",
                    template: itemRenderer,
                },
                // {
                //     name: 'sale_estimate',
                //     label: 'Sale Estimate',
                //     arrow: "pi-plus",
                //     Fcode: "SES",
                //     template: itemRenderer,
                // },
                {
                    name: 'SaleOrder',
                    label: 'Sale Order',
                    arrow: "pi-plus",
                    Fcode: "SOR",
                    template: itemRenderer,
                },
                {
                    name: 'SaleReturn',
                    label: 'Sale Return',
                    arrow: "pi-plus",
                    Fcode: "SRT",
                    template: itemRenderer,
                },
            ],
        },
        {
            label: 'Purchase',
            icon: 'pi pi-shopping-cart',
            arrow: "pi-angle-down",
            Fcode: "PRC",
            template: itemRenderer2,
            items: [
                {
                    name: 'PurchaseBills',
                    label: 'Purchase Bills',
                    arrow: "pi-plus",
                    Fcode: "PRB",
                    template: itemRenderer,
                },
                {
                    name: 'PurchaseReturn',
                    label: 'Purchase Return',
                    arrow: "pi-plus",
                    Fcode: "PUR",
                    template: itemRenderer,
                }
            ]
        },
        {
            label: 'Expensive',
            name: 'Expensive',
            icon: 'pi pi-wallet',
            arrow: "pi-plus",
            Fcode: "EXP",
            template: itemRenderer,
        },
        {
            name: 'Account',
            label: 'Account',
            icon: 'pi pi-book',
            arrow: "pi-angle-down",
            Fcode: "ACC",
            template: itemRenderer2,
            items: [
                {
                    name: 'PaymentIn',
                    label: 'Payment In',
                    Fcode: "PIN",
                    template: itemRenderer,
                },
                {
                    name: 'PaymentOut',
                    label: 'Payment Out',
                    Fcode: "POU",
                    template: itemRenderer,
                }
            ]
        },
        {
            label: 'Bank & Cash',
            icon: 'pi pi-building-columns',
            arrow: "pi-angle-down",
            Fcode: "BNK",
            template: itemRenderer2,
            items: [
                {
                    label: 'Bank Accounts',
                    name: 'bank_accounts',
                    Fcode: "BAC",
                    template: itemRenderer,
                },
                {
                    label: 'Cash In Hand',
                    name: 'cash_in_hand',
                    Fcode: "CIH",
                    template: itemRenderer,
                },
                {
                    label: 'Cheques',
                    name: 'cheques',
                    Fcode: "CHQ",
                    template: itemRenderer,
                },
            ],
        },
        {
            label: 'Report',
            name: 'Report',
            icon: 'pi pi-chart-line',
            Fcode: "REP",
            template: itemRenderer,
        },
        // {
        //     label: 'Utilities',
        //     name: 'Utilities',
        //     icon: 'pi pi-wrench',
        //     arrow: "pi-angle-down",
        //     Fcode: "UTL",
        //     template: itemRenderer2,
        //     items: [
        //         {
        //             label: 'Bulk Update Items',
        //             name: 'bulk_update_items',
        //             Fcode: "BUK",
        //             template: itemRenderer,
        //         },
                // {
                //     label: 'Barcode Generator',
                //     name: 'barcode_generator',
                //     Fcode: "BRG",
                //     template: itemRenderer,
                // }
        //     ]
        // },
        {
            label: 'Settings',
            name: 'Settings',
            icon: 'pi pi-cog',
            arrow: "pi-angle-down",
            Fcode: "SET",
            template: itemRenderer2,
            items: [
                {
                    label: 'General',
                    name: 'General',
                    Fcode: "GEN",
                    template: itemRenderer,
                },
                {
                    label: 'Taxes & Gst',
                    name: 'taxes_gst',
                    Fcode: "TAX",
                    template: itemRenderer,
                },
                {
                    label: 'Print',
                    name: 'Prints',
                    Fcode: "PRS",
                    template: itemRenderer,
                },
                {
                    label: 'WhatsApp Message',
                    name: 'transaction_message',
                    Fcode: "WMS",
                    template: itemRenderer,
                },
                {
                    label: 'General Message',
                    name: 'general_message',
                    Fcode: "GMS",
                    template: itemRenderer,
                },
                // {
                //     label: 'Form Master',
                //     name: 'form_master',
                //     Fcode: "FRM",
                //     template: itemRenderer,
                // },
            ]
        },
        {
            label: 'User',
            name: 'user',
            icon: 'pi pi-user-plus',
            arrow: "pi-angle-down",
            Fcode: "USR",
            template: itemRenderer2,
            items: [
                {
                    label: 'New User',
                    name: 'new_user',
                    Fcode: "NUR",
                    template: itemRenderer,
                },
                {
                    label: 'User Permission',
                    name: 'user_permission',
                    Fcode: "UPR",
                    template: itemRenderer,
                },
            ]
        }
    ]);

    return (
        <div id='Sidebar' className={props.siderOpen ? "sideBarOpen" : "sideBarClose"}>
            <div className='Blacklayout' onClick={() => props.setSidebarOpen(false)}></div>

            <div className='main'>
                <div className='company'>
                    <div className='image'>
                        <img src={img} alt='Gadhiya technologies' />
                    </div>
                    <h2>AccoHisab<i className="pi pi-angle-right"></i></h2>
                    <div className='arrow' onClick={() => { !props.siderOpen ? props.setSidebarOpen(true) : props.setSidebarOpen(false) }}>
                        {props.siderOpen ?
                            <i className="pi pi-angle-left"></i>
                            :
                            <i className="pi pi-angle-right"></i>
                        }
                    </div>
                </div>
                <div className='menu' >
                    <div className="card flex justify-content-center">
                        <PanelMenu model={items} className={`w-full ${props.siderOpen ? "open" : "close"}`} />
                    </div>
                </div>
                <div className="foter-title">
                    <h5 onClick={() => window.open('https://gadhiyatechnologies.com', '_blank')}> Developed by Gadhiya Technologies</h5>
                </div>
            </div>
            <AddParties visible={visible} setVisible={setVisible} />
        </div>
    );
}

export default Sidebar;