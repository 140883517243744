import CryptoJS from 'crypto-js';
import PathConstants from '../../Routes/pathConstants';

function parseDate(dateStr) {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day);
}

function formatDate(date) {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
};

const validatePassword = (password) => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const correctPassword = `GT${day}${month}`;

    if (password === correctPassword) {
        return { isValid: true, message: '' };
    } else {
        return { isValid: false, message: 'Incorrect password, please try again.' };
    }
};


const getFcodeByPath = (pathName) => {
    const route = Object.values(PathConstants).find(route => route.path === pathName);
    return route ? route.Fcode : null;
};



const getTimeAgo = (timestamp) => {
    const now = new Date();
    const past = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - past) / 1000);

    if (differenceInSeconds < 60) {
        return `${differenceInSeconds} seconds ago`;
    } else if (differenceInSeconds < 3600) {
        const minutes = Math.floor(differenceInSeconds / 60);
        return `${minutes} minutes ago`;
    } else if (differenceInSeconds < 86400) {
        const hours = Math.floor(differenceInSeconds / 3600);
        return `${hours} hours ago`;
    } else {
        const days = Math.floor(differenceInSeconds / 86400);
        return `${days} days ago`;
    }
};


function numberToWords(num) {
    const a = [
        "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen",
    ];
    const b = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    const convert = (n) => {
        if (n < 20) return a[n];
        if (n < 100) return b[Math.floor(n / 10)] + (n % 10 !== 0 ? " " + a[n % 10] : "");
        if (n < 1000) return a[Math.floor(n / 100)] + " Hundred" + (n % 100 !== 0 ? " " + convert(n % 100) : "");
        if (n < 100000) return convert(Math.floor(n / 1000)) + " Thousand" + (n % 1000 !== 0 ? " " + convert(n % 1000) : "");
        if (n < 10000000) return convert(Math.floor(n / 100000)) + " Lakh" + (n % 100000 !== 0 ? " " + convert(n % 100000) : "");
        return convert(Math.floor(n / 10000000)) + " Crore" + (n % 10000000 !== 0 ? " " + convert(n % 10000000) : "");
    };

    return num ? convert(num) + " Rupees Only" : "";
}



const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const encryptLocalData = (key, value) => {
    if (!SECRET_KEY) return console.error("Encryption failed: SECRET_KEY is not set.");

    try {
        const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
        localStorage.setItem(key, encryptedValue);
    } catch (error) {
        console.error("Error encrypting data:", error);
    }
};

export const decryptLocalData = (key) => {
    if (!SECRET_KEY) return console.error("Decryption failed: SECRET_KEY is not set.");

    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedValue);
    } catch (error) {
        console.error("Error decrypting data:", error);
        return null;
    }
};

const key = process.env.REACT_APP_SECRET_KEY;

function decryptData(encryptedData, iv, key) {
    try {
        const ivWordArray = CryptoJS.enc.Hex.parse(iv);
        const keyWordArray = CryptoJS.enc.Hex.parse(key);

        const encryptedWordArray = CryptoJS.enc.Hex.parse(encryptedData);

        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encryptedWordArray },
            keyWordArray,
            { iv: ivWordArray, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );

        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

        if (!decryptedText) throw new Error("Decryption failed.");

        return decryptedText;
    } catch (error) {
        console.error("Decryption error:", error.message);
        return null;
    }
}

const encryptData = (data, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), keyHex, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return {
        encryptedData: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
        iv: iv.toString(CryptoJS.enc.Base64),
    };
};



export {
    parseDate,
    formatDate,
    validatePassword,
    getFcodeByPath,
    getTimeAgo,
    numberToWords,
    decryptData,
    encryptData,
    key,
}
