// App.js
import React, { useState, useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import routes from "./Routes";
import Layout from "./Routes/Layout";
import Login from './Login/Login';
import { jwtDecode } from 'jwt-decode';
import Page500 from './components/Pages/Page500/Page500';
import Page404 from './components/Pages/Page404/Page404';
import { SocketContext, socket } from './components/SocketContext/SocketContext';
import './App.scss'
import KeyEventHandler from './components/KeyEventHandler/KeyEventHandler';
import UserInfoDialog from './components/UserInfoDialog/UserInfoDialog';
import { Post } from './Config/config';
import { decryptData, decryptLocalData, encryptData, key } from './components/GlobalFunction/GlobalFunction';

function App() {
  const [isLogin, setIsLogin] = useState(decryptLocalData('ID') ? true : false);
  const [showDialog, setShowDialog] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [firmData, setFirmData] = useState(JSON.parse(localStorage.getItem('userData')));

  const inactivityTimeout = useRef(null);

  function LocationListener() {
    const location = useLocation();

    const getLastSegment = () => {
      const urlPath = window.location.pathname;
      const lastSegment = urlPath.split('/').pop();
      sessionStorage.setItem('F_code', lastSegment);
      return lastSegment;
    };

    useEffect(() => {
      getLastSegment();
    }, [location]);

    return null;
  }

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setIsLogin(!!sessionStorage.getItem('header'));
  //   }, 100);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsLogin(!!decryptLocalData('ID'));
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  // const checkForUserData = () => {
  //   const userData = localStorage.getItem('userData');
  //   if (!userData) {
  //     window.location.assign('/404');
  //   }
  // };

  // useEffect(() => {
  //   if (isLogin === true) {
  //     checkForUserData();
  //   }
  // }, []);

  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "/login" || window.location.pathname === "/register") {
      sessionStorage.removeItem("header");
      localStorage.clear();
    }

    const handleActivity = () => {
      clearTimeout(inactivityTimeout.current);
      inactivityTimeout.current = setTimeout(() => {
        sessionStorage.removeItem("header");
        localStorage.clear();
        setIsLogin(false);
        checkToken();
      }, 10 * 60 * 60 * 1000);
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    return () => {
      clearTimeout(inactivityTimeout.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);

  useEffect(() => {
    if (isLogin) {
      const userFill = async () => {
        try {
          const payload = {
            FirmId: 1
          };

          const { encryptedData, iv } = encryptData(payload, key);
          const res = await Post('Firm/Fill', { encryptedData, iv });

          const decryptedRes = decryptData(res.encryptedData, res.iv, key);

          if (!decryptedRes) {
            throw new Error("Decryption failed or response is empty.");
          }

          const parsedRes = JSON.parse(decryptedRes);
          const userData = parsedRes.UserData[0];

          if (userData.BusinessName && userData.MobileNo) {
            setBusinessName(userData.BusinessName);
            setMobileNo(userData.MobileNo);
          } else {
            setShowDialog(true);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      if (!firmData || !firmData.BusinessName) {
        userFill();
      }
    }
  }, [isLogin]);

  const checkToken = () => {
    const token = decryptLocalData('ID');
    if (token) {
      const decodedToken = jwtDecode(token, "1234567890123456789");
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        setIsLogin(false);
      } else {
        setIsLogin(true);
      };
    } else {
      setIsLogin(false);
    }
  };

  const router = createBrowserRouter([
    {
      element: isLogin ? (
        <SocketContext.Provider value={socket}>
          <Layout />
          <KeyEventHandler />
          <LocationListener />
          <UserInfoDialog visible={showDialog} onHide={() => setShowDialog(false)} onSave={() => setShowDialog(false)} />
        </SocketContext.Provider>
      ) : (
        <Login />
      ),
      errorElement: window.location.pathname === '/500' ? <Page500 /> : <Page404 />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
