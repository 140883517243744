import React from "react"
import PathConstants from "../Routes/pathConstants";
import { Navigate, useParams } from "react-router-dom";

const Demo = React.lazy(() => import("../Demo/Demo"));
const Login = React.lazy(() => import("../Login/Login"));
const Register = React.lazy(() => import("../Login/Login"));
const Home = React.lazy(() => import("../components/Pages/Home/Home"));
const Items = React.lazy(() => import("../components/Pages/Items/Items"));
const Parties = React.lazy(() => import("../components/Pages/Parties/Parties"));
const PurchaseBills = React.lazy(() => import("../components/Pages/Purchase/PurchaseBills/PurchaseBills"));
const PurchaseReturn = React.lazy(() => import("../components/Pages/Purchase/PaymentReturn/PaymentReturn"));
const SaleReturn = React.lazy(() => import("../components/Pages/Sale/SaleReturn/SaleReturn"));
const SaleInvoices = React.lazy(() => import("../components/Pages/Sale/SaleInvoices/SaleInvoices"));
const PaymentIn = React.lazy(() => import("../components/Pages/Account/PaymentIn/PaymentIn"));
const PaymentOut = React.lazy(() => import("../components/Pages/Account/PaymentOut/PaymentOut"));
const AddItems = React.lazy(() => import("../components/Pages/Items/AddItems/AddItems"));
const Print = React.lazy(() => import("../components/Pages/Settings/Print/Print"));
const AddSaleInvoices = React.lazy(() => import("../components/Pages/Sale/SaleInvoices/AddSaleInvoices/AddSaleInvoices"));
const AddCreditNote = React.lazy(() => import("../components/Pages/Sale/SaleReturn/AddCreditNote/AddCreditNote"));
const Expensive = React.lazy(() => import("../components/Pages/Expensive/Expensive"));
const AddExpensive = React.lazy(() => import("../components/Pages/Expensive/AddExpensive/AddExpensive"));
const AddDebitNote = React.lazy(() => import("../components/Pages/Purchase/PaymentReturn/AddDebitNote/AddDebitNote"));
const AddPurchaseBills = React.lazy(() => import("../components/Pages/Purchase/PurchaseBills/AddPurchaseBills/AddPurchaseBills"));
const Profile = React.lazy(() => import("../components/Pages/Profile/Profile"));
const Report = React.lazy(() => import("../components/Pages/Reports/Report"));
const SaleOrder = React.lazy(() => import("../components/Pages/Sale/SaleOrder/SaleOrder"));
const SaleAddOrder = React.lazy(() => import("../components/Pages/Sale/SaleOrder/AddSaleOrder/AddSaleOrder"));
const General = React.lazy(() => import("../components/Pages/Settings/General/General"));
const TransactionMessage = React.lazy(() => import("../components/Pages/Settings/TransactionMessage/TransactionMessage"));
const TaxesGst = React.lazy(() => import("../components/Pages/Settings/TaxesGst/TaxesGst"));
const FormMaster = React.lazy(() => import("../components/Pages/Settings/FormMaster/FormMaster"));
const UserPermission = React.lazy(() => import("../components/Pages/User/UserPermission/UserPermission"));
const NewUser = React.lazy(() => import("../components/Pages/User/NewUser/NewUser"));
const BankAccounts = React.lazy(() => import("../components/Pages/BankCash/BankAccounts/BankAccounts"));
const CashInHand = React.lazy(() => import("../components/Pages/BankCash/CashInHand/CashInHand"));
const Cheques = React.lazy(() => import("../components/Pages/BankCash/Cheques/Cheques"));
const GeneralMessage = React.lazy(() => import("../components/Pages/Settings/GeneralMessage/GeneralMessage"));
const BulkUpdateItems = React.lazy(() => import("../components/Pages/Utilities/BulkUpdateItems/BulkUpdateItems"));
const SaleEstimate = React.lazy(() => import("../components/Pages/Sale/SaleEstimate/SaleEstimate"));
const AddSaleEstimate = React.lazy(() => import("../components/Pages/Sale/SaleEstimate/AddSaleEstimate/AddSaleEstimate"));
const BarcodeGenerator = React.lazy(() => import("../components/Pages/Utilities/BarcodeGenerator/BarcodeGenerator"));

const FcodeValidator = ({ element, expectedFcode }) => {
    const { Fcode } = useParams();
    if (Fcode !== expectedFcode) {
        return <Navigate to="/404" replace />;
    }
    return element;
};

const routes = [
    { path: PathConstants.Demo.path, element: <Demo /> },
    { path: PathConstants.Login.path, element: <Login /> },
    { path: PathConstants.Register.path, element: <Register /> },
    { path: PathConstants.LoginPage.path, element: <Login /> },
    { path: `${PathConstants.Home.path}/:Fcode`, element: <FcodeValidator element={<Home />} expectedFcode={PathConstants.Home.Fcode} /> },
    { path: `${PathConstants.Items.path}/:Fcode`, element: <FcodeValidator element={<Items />} expectedFcode={PathConstants.Items.Fcode} /> },
    { path: `${PathConstants.Parties.path}/:Fcode`, element: <FcodeValidator element={<Parties />} expectedFcode={PathConstants.Parties.Fcode} /> },
    { path: `${PathConstants.PurchaseBills.path}/:Fcode`, element: <FcodeValidator element={<PurchaseBills />} expectedFcode={PathConstants.PurchaseBills.Fcode} /> },
    { path: `${PathConstants.PurchaseReturn.path}/:Fcode`, element: <FcodeValidator element={<PurchaseReturn />} expectedFcode={PathConstants.PurchaseReturn.Fcode} /> },
    { path: `${PathConstants.SaleReturn.path}/:Fcode`, element: <FcodeValidator element={<SaleReturn />} expectedFcode={PathConstants.SaleReturn.Fcode} /> },
    { path: `${PathConstants.SaleInvoices.path}/:Fcode`, element: <FcodeValidator element={<SaleInvoices />} expectedFcode={PathConstants.SaleInvoices.Fcode} /> },
    { path: `${PathConstants.PaymentIn.path}/:Fcode`, element: <FcodeValidator element={<PaymentIn />} expectedFcode={PathConstants.PaymentIn.Fcode} /> },
    { path: `${PathConstants.PaymentOut.path}/:Fcode`, element: <FcodeValidator element={<PaymentOut />} expectedFcode={PathConstants.PaymentOut.Fcode} /> },
    { path: `${PathConstants.AddItems.path}/:Fcode`, element: <FcodeValidator element={<AddItems />} expectedFcode={PathConstants.AddItems.Fcode} /> },
    { path: `${PathConstants.Print.path}/:Fcode`, element: <FcodeValidator element={<Print />} expectedFcode={PathConstants.Print.Fcode} /> },
    { path: `${PathConstants.AddSaleInvoices.path}/:Fcode`, element: <FcodeValidator element={<AddSaleInvoices />} expectedFcode={PathConstants.AddSaleInvoices.Fcode} /> },
    { path: `${PathConstants.AddCreditNote.path}/:Fcode`, element: <FcodeValidator element={<AddCreditNote />} expectedFcode={PathConstants.AddCreditNote.Fcode} /> },
    { path: `${PathConstants.Expensive.path}/:Fcode`, element: <FcodeValidator element={<Expensive />} expectedFcode={PathConstants.Expensive.Fcode} /> },
    { path: `${PathConstants.AddExpensive.path}/:Fcode`, element: <FcodeValidator element={<AddExpensive />} expectedFcode={PathConstants.AddExpensive.Fcode} /> },
    { path: `${PathConstants.AddDebitNote.path}/:Fcode`, element: <FcodeValidator element={<AddDebitNote />} expectedFcode={PathConstants.AddDebitNote.Fcode} /> },
    { path: `${PathConstants.AddPurchaseBills.path}/:Fcode`, element: <FcodeValidator element={<AddPurchaseBills />} expectedFcode={PathConstants.AddPurchaseBills.Fcode} /> },
    { path: `${PathConstants.Profile.path}/:Fcode`, element: <FcodeValidator element={<Profile />} expectedFcode={PathConstants.Profile.Fcode} /> },
    { path: `${PathConstants.Report.path}/:Fcode`, element: <FcodeValidator element={<Report />} expectedFcode={PathConstants.Report.Fcode} /> },
    { path: `${PathConstants.SaleOrder.path}/:Fcode`, element: <FcodeValidator element={<SaleOrder />} expectedFcode={PathConstants.SaleOrder.Fcode} /> },
    { path: `${PathConstants.SaleAddOrder.path}/:Fcode`, element: <FcodeValidator element={<SaleAddOrder />} expectedFcode={PathConstants.SaleAddOrder.Fcode} /> },
    { path: `${PathConstants.General.path}/:Fcode`, element: <FcodeValidator element={<General />} expectedFcode={PathConstants.General.Fcode} /> },
    { path: `${PathConstants.TransactionMessage.path}/:Fcode`, element: <FcodeValidator element={<TransactionMessage />} expectedFcode={PathConstants.TransactionMessage.Fcode} /> },
    { path: `${PathConstants.TaxesGst.path}/:Fcode`, element: <FcodeValidator element={<TaxesGst />} expectedFcode={PathConstants.TaxesGst.Fcode} /> },
    { path: `${PathConstants.FormMaster.path}/:Fcode`, element: <FcodeValidator element={<FormMaster />} expectedFcode={PathConstants.FormMaster.Fcode} /> },
    { path: `${PathConstants.UserPermission.path}/:Fcode`, element: <FcodeValidator element={<UserPermission />} expectedFcode={PathConstants.UserPermission.Fcode} /> },
    { path: `${PathConstants.NewUser.path}/:Fcode`, element: <FcodeValidator element={<NewUser />} expectedFcode={PathConstants.NewUser.Fcode} /> },
    { path: `${PathConstants.CashInHand.path}/:Fcode`, element: <FcodeValidator element={<CashInHand />} expectedFcode={PathConstants.CashInHand.Fcode} /> },
    { path: `${PathConstants.Cheques.path}/:Fcode`, element: <FcodeValidator element={<Cheques />} expectedFcode={PathConstants.Cheques.Fcode} /> },
    { path: `${PathConstants.BankAccounts.path}/:Fcode`, element: <FcodeValidator element={<BankAccounts />} expectedFcode={PathConstants.BankAccounts.Fcode} /> },
    { path: `${PathConstants.GeneralMessage.path}/:Fcode`, element: <FcodeValidator element={<GeneralMessage />} expectedFcode={PathConstants.GeneralMessage.Fcode} /> },
    { path: `${PathConstants.BulkUpdateItems.path}/:Fcode`, element: <FcodeValidator element={<BulkUpdateItems />} expectedFcode={PathConstants.BulkUpdateItems.Fcode} /> },
    { path: `${PathConstants.SaleEstimate.path}/:Fcode`, element: <FcodeValidator element={<SaleEstimate />} expectedFcode={PathConstants.SaleEstimate.Fcode} /> },
    { path: `${PathConstants.AddSaleEstimate.path}/:Fcode`, element: <FcodeValidator element={<AddSaleEstimate />} expectedFcode={PathConstants.AddSaleEstimate.Fcode} /> },
    { path: `${PathConstants.BarcodeGenerator.path}/:Fcode`, element: <FcodeValidator element={<BarcodeGenerator />} expectedFcode={PathConstants.BarcodeGenerator.Fcode} /> },

];

export default routes;
