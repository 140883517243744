import { createContext } from 'react';
import io from 'socket.io-client';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const socket = io(apiUrl, {
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
});

export const SocketContext = createContext(socket);
export { socket };